import * as React from 'react';
import { Provider } from 'react-redux';
import Home from './Screens/Home';
import Saved from './Screens/Saved';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  SafeAreaView,
  AppRegistry,
  Dimensions,
} from 'react-native';
import Constants from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import axios from 'axios';
/* import LinearGradient from 'react-native-linear-gradient'; */
import Info from './Screens/Info';

const backgroundColor = '#EEE';
const backgroundColorDark = '#000';
const buttonColor = '#D9D9D9';
const buttonPressedColor = '#FFF'
const createButtonColor = '#000';
const actionButtonColor = 'rgba(78, 76, 81, 0.6)';
const textColor = '#000';
const textColorDark = '#FFF';


const tileContent = {
  "Message": '📩',
  "Email": '📧',
  "Greeting": '👋',
  "Love letter": '❤️',
  "Thank you note": '🙏',
  "Invitation": '🎉',
  "Essay": '📝',
  "Poem": '📜',
  "Apology letter": '🙏',
  "Joke": '😂',
  'Blog post': '💻',
  'Rap song': '🎤',
  "Tweet": '🐦', 
  'LinkedIn post': '💼',
  'Science article': '📊',
  'Cover letter': '📎',
  "Play": '🎭',
  "Musical": '🎵',
};

var outputReady = false;

const inspireInput = [
{'type': 'Play', 'text': 'A comical retelling of the Trojan War', 'wordLength': '150', 'style': 'Satirical'},
{'type': 'Poem', 'text': 'A love letter to my favorite food', 'wordLength': '250', 'style': 'Romantic'},
{'type': 'Email', 'text': 'An apology to my boss for being late to work', 'wordLength': 'Custom', 'style': 'Apologetic'},
{'type': 'LinkedIn post', 'text': 'Why every business should invest in sustainable practices', 'wordLength': '50', 'style': 'Persuasive'},
{'type': 'Greeting', 'text': 'A birthday message for my best friend', 'wordLength': '150', 'style': 'Funny'},
{'type': 'Essay', 'text': 'The importance of mental health in the workplace', 'wordLength': '250', 'style': 'Informative'},
{'type': 'Blog post', 'text': 'My top 10 travel destinations', 'wordLength': 'Custom', 'style': 'Descriptive'},
{'type': 'Love letter', 'text': 'Why I love you more than words can say', 'wordLength': '50', 'style': 'Romantic'},
{'type': 'Science article', 'text': 'The role of technology in modern education', 'wordLength': '150', 'style': 'Informative'},
{'type': 'Invitation', 'text': 'Come join me for a beach party', 'wordLength': '250', 'style': 'Casual'},
{'type': 'Thank you note', 'text': 'To the kind stranger who returned my lost wallet', 'wordLength': '50', 'style': 'Grateful'},
{'type': 'Joke', 'text': "Why couldn't the bicycle stand up by itself?", 'wordLength': '150', 'style': 'Funny'},
{'type': 'Story', 'text': 'The adventures of a brave mouse in the big city', 'wordLength': '250', 'style': 'Narrative'},
{'type': 'Apology letter', 'text': 'To my neighbor for the loud party last night', 'wordLength': 'Custom', 'style': 'Apologetic'},
{'type': 'Musical', 'text': 'A love story set in the 1920s', 'wordLength': '250', 'style': 'Romantic'},
{'type': 'Cover letter', 'text': 'Why I am the perfect candidate for this consulting job', 'wordLength': '150', 'style': 'Professional'},
{'type': 'Tweet', 'text': 'The best thing about being a cat', 'wordLength': '250', 'style': 'Funny'},
{'type': 'Science article', 'text': 'The latest research on climate change', 'wordLength': 'Custom', 'style': 'Scientific'},
{'type': 'Message', 'text': 'A reminder to pay the rent on time', 'wordLength': '50', 'style': 'Professional'},
{'type': 'Rap song', 'text': "Why pineapple doesn't belong on pizza", 'wordLength': '150', 'style': 'Satirical'},
{'type': 'Play', 'text': "A modern retelling of Shakespeare's Romeo and Juliet", 'wordLength': '250', 'style': 'Creative'},
{'type': 'Poem', 'text': 'A tribute to the beauty of nature', 'wordLength': 'Custom', 'style': 'Descriptive'},
{'type': 'Email', 'text': 'An update on the progress of a project', 'wordLength': '50', 'style': 'Professional'},
{'type': 'LinkedIn post', 'text': '5 tips for networking success', 'wordLength': '150', 'style': 'Professional'},
{'type': 'Greeting', 'text': 'A holiday message to my family', 'wordLength': '250', 'style': 'Casual'},
{'type': 'Essay', 'text': 'The benefits of learning a second language', 'wordLength': 'Custom', 'style': 'Informative'},
{'type': 'Blog post', 'text': 'My top 10 healthy meal prep ideas', 'wordLength': '50', 'style': 'Informative'},
{'type': 'Love letter', 'text': "Why I can't wait to spend the rest of my life with you", 'wordLength': '150', 'style': 'Romantic'},
{'type': 'Science article', 'text': 'Historical developments in renewable energy', 'wordLength': '250', 'style': 'Informative'},
{'type': 'Invitation', 'text': 'Come celebrate my birthday with me', 'wordLength': 'Custom', 'style': 'Casual'},]

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {AsyncStorage} from '@react-native-async-storage/async-storage';


// You can import from local files
// or any pure javascript modules available in npm
let ScreenHeight = Dimensions.get('window').height;

const Stack = createStackNavigator();

const Tab = createBottomTabNavigator();

export default function App() {

  return (
    <NavigationContainer>
      <SafeAreaProvider>
        <Tab.Navigator>
          <Tab.Screen name="HomeScreen" component={Home} 
          options={{
            headerShown: false,
          }}
          />
          <Tab.Screen name="Saved" component={Saved}

          />
          </Tab.Navigator>
        
      </SafeAreaProvider>
    </NavigationContainer>
  );
}


