import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  ScrollView,
  SafeAreaView,
  Modal,
  FlatList,
  Share,
  Alert,
  ActivityIndicator,
  Platform,
  Clipboard,
  Keyboard,
  Linking,
  Appearance,
  useColorScheme,
  Image,
  KeyboardAvoidingView,
} from 'react-native';

import { Card, normalize } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import axios from 'axios';
import Info from './Info';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {LinearGradient} from 'expo-linear-gradient';
import { color } from 'react-native-elements/dist/helpers';

// var LinearGradient = require('../node_modules/expo').default

const backgroundColor = '#9FFCA3';
const backgroundColorDark = '#262626';
const buttonColor = '#FFF';
const buttonColorDark = '#000'
const buttonPressedColor = '#000'
const buttonPressedColorDark = '#FFF'
const createButtonColor = '#282424';
const createButtonColorDark = '#FFF'
const actionButtonColor = '#000';
const textColor = '#000';
const textColorDark = '#FFF';


const tileContent = {
  "Poem": '📜',
  'Science article': '📊',
  'Rap song': '🎤',
  "Essay": '📝',
  "Apology letter": '🙏',
  "Short story": '📖',
  'Blog post': '💻',
  "Tweet": '🐦', 
  'LinkedIn post': '💼',
  'Cover letter': '📎',
  "Play": '🎭',
  "Musical": '🎵',
};

const aboutContent = {
  "Droughts": '🌍',
  "Global warming": '🌡️',
  "Sea level rise": '🌊',
  "Endangered species": '🐘',
  "Glacier melting": '🗻',
  "Recycling": '♻️',
  "Plastics": '🌊',
  "Deforestation": '🌲',
  "Carbon emissions": '🔥',
  "Renewable energy": '💡',
  "Climate action": '🌍',
  "Sustainable living": '🏡',
  "Ocean acidification": '🌊',
  "Air pollution": '💨',
  "Water scarcity": '💧',
  "Food security": '🌽',
  "Natural disasters": '🌪️',
  "Biodiversity loss": '🌎',
  "Arctic sea ice": '🐋',
  "Climate justice": '⚖️',
  "Greenhouse gases": '🌿',
  "Alternative transportation": '🚲',
  "Energy efficiency": '💡',
  "Conservation": '🐢',
  "Agricultural practices": '🌾',
  "Forestry": '🌲',
  "Desertification": '🏜️',
  "Urbanization": '🏙️',
  "Community resilience": '🏡',
  "Emissions trading": '💰',
  "Methane emissions": '🐄'
};

var outputReady = false;

const inspireInput = [
  {'type': 'Essay', 'text': 'A critical analysis of the role of governments in addressing climate change', 'wordLength': '50', 'style': 'Informative'},
  {'type': 'Musical', 'text': 'A musical about the importance of renewable energy', 'wordLength': '250', 'style': 'Inspiring'},
  {'type': 'Blog post', 'text': 'A personal reflection on the impact of climate change on my daily life', 'wordLength': 'Custom', 'style': 'Personal'},
  {'type': 'Tweet', 'text': '10 simple ways to reduce your carbon footprint', 'wordLength': '150', 'style': 'Informative'},
  {'type': 'Invitation', 'text': 'Invitation to a community climate strike', 'wordLength': '50', 'style': 'Inspiring'},
  {'type': 'Science article', 'text': 'The science behind ocean acidification and its impacts on marine life', 'wordLength': '250', 'style': 'Informative'},
  {'type': 'Thank you note', 'text': 'Thanking a friend for their efforts in promoting sustainability', 'wordLength': '50', 'style': 'Thankful'},
  {'type': 'Greeting', 'text': 'A greeting card for Earth Day', 'wordLength': '50', 'style': 'Celebratory'},
  {'type': 'Message', 'text': 'A message to a friend about the importance of reducing plastic use', 'wordLength': '50', 'style': 'Informative'},
  {'type': 'Apology letter', 'text': 'An apology to the Earth for not doing enough to address climate change', 'wordLength': 'Custom', 'style': 'Apologetic'},
  {'type': 'Blog post', 'text': 'Deforestation', 'wordLength': '250', 'style': 'Inspirational'},
  {'type': 'Poem', 'text': 'Climate action', 'wordLength': '150', 'style': 'Inspirational'},
  {'type': 'Play', 'text': 'Energy efficiency', 'wordLength': '50', 'style': 'Inspirational'},
  {'type': 'Message', 'text': 'Conservation', 'wordLength': '150', 'style': 'Inspirational'},
  {'type': 'Thank you note', 'text': 'Agricultural practices', 'wordLength': '250', 'style': 'Inspirational'},
  {'type': 'Invitation', 'text': 'Biodiversity loss', 'wordLength': '50', 'style': 'Inspirational'},
  {'type': 'Tweet', 'text': 'Global warming', 'wordLength': '150', 'style': 'Inspirational'},
  {'type': 'Science article', 'text': 'Droughts', 'wordLength': '250', 'style': 'Inspirational'},
  {'type': 'LinkedIn post', 'text': 'Endangered species', 'wordLength': '50', 'style': 'Inspirational'},
  {'type': 'Essay', 'text': 'Forestry', 'wordLength': '150', 'style': 'Inspirational'},
  {'type': 'Apology letter', 'text': 'Greenhouse gases', 'wordLength': '250', 'style': 'Inspirational'},
  {'type': 'Rap song', 'text': 'Natural disasters', 'wordLength': '50', 'style': 'Inspirational'},
  {'type': 'Musical', 'text': 'Sea level rise', 'wordLength': '150', 'style': 'Inspirational'},
];

function randomNumberInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const data = Object.entries(tileContent).map(([key, value]) => ({
  key,
  text: key,
  icon: value,
}));

const styleToEmoji = {
  Funny: '😂',
  Pirate: '🏴‍☠️',
  Casual: '👕',
  Professional: '💼',
  Romantic: '💘',
  Poetic: '📜',
  Inspirational: '🔥',
  Suspenseful: '😱',
  Satirical: '😜',
  Apologetic: '🙏',
  Flirty: '😘',
  Formal: '🎩',
  Scientific: '🔬',
  Persuasive: '👊',
  Descriptive: '🌅',
  Clever: '🧠',
  Informative: '📚',
  Grateful: '🙏',
  Creative: '🎨',
};

const AfterCreate = ({
  outputText,
  setOutputText,
  handleCreatePress,
  isLoading,
  resultVisible,
  setResultVisible,
  setPostCreation,
  theme
}) => {

//create global variable counter

const storeData = async (value) => {
  const key = Date.now().toString();
  const jsonValue = JSON.stringify(value);
  await AsyncStorage.setItem(key, jsonValue);
}

    // Generate a unique key based on the counter
  //   const key = counterSave 
  //   console.log(key)
  //   const keyCase = key.toString()
  //   setCounterSave(counterSave+1)
  //   console.log(keyCase)
  //   const jsonValue = JSON.stringify(value)
  //   await AsyncStorage.setItem(keyCase, jsonValue)
  // } catch (e) {
  //   // saving error
  // }
// }

  const handleShare = async () => {
    if (!outputReady) {
      if (Platform.OS === 'web') {
        alert('Please wait until the response is generated');
      } else {
        Alert.alert(
          'Response not yet generated',
          'Please wait for the response'
        );
      }
      return;
    }
    await Share.share({
      message: outputText,
    })
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  };

  function onCopy({ outputText }) {
    // Clipboard.set(outputText);
    if (!outputReady) {
      if (Platform.OS === 'web') {
        alert('Please wait until the response is generated');
      } else {
        Alert.alert(
          'Response not yet generated',
          'Please wait for the response'
        );
      }
      return;
    }
    
    Clipboard.setString(outputText);
    /*
    const type = "text/plain";
    const blob = new Blob([outputText], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    console.log(navigator.clipboard.write(data));
    */
    console.log('Text copied to clipboard');
    console.log(outputText);
  }

  const handleRecreatePress = () => {
    if (!outputReady) {
      if (Platform.OS === 'web') {
        alert('Please wait until the response is generated');
      } else {
        Alert.alert(
          'Response not yet generated',
          'Please wait for the response'
        );
      }
      return;
    }
    setOutputText('Waiting on a new response...');
    handleCreatePress();
  };

  const handleExPress = () => {
    setResultVisible(false);
    setPostCreation(false);
    outputReady = false;
    setOutputText('Waiting on response...');
  }

const iconColor = (theme === 'dark' ? 'white': 'black'); 
  return (
    <Modal animationType="slide" transparent={false} visible={resultVisible} >
    <SafeAreaView style={[{ position: 'relative'}, theme === 'dark' ? styles.darkContainer : styles.lightContainer]}>
      <TouchableOpacity
          style={{flexDirection: 'row',
          justifyContent: 'flex-end',
          margin: 10
          }}
          onPress={handleExPress}>
          <Ionicons name="ios-close" size={32} color={iconColor} />
          </TouchableOpacity>
          {!isLoading &&
          <View style={{ flexDirection: 'row', alignItems: 'right'}}>
          <TouchableOpacity
            style={styles.copyButton}
            onPress={() => onCopy({outputText})}>
            <Text style={styles.createButtonText}>Copy</Text>
          </TouchableOpacity></View>}
          {isLoading && 
          <ActivityIndicator size="large" color="#111111"/>
          }
          {isLoading && 
          <Text style={styles.waitingMessage}>This will take up to 15 seconds depending on the word count.</Text>
          }
      {!isLoading && (
        <ScrollView>
          <Card styles={{ flex: 1 }}>
            <Text style={styles.resultTitle}>Result</Text>
            <Text selectable>{outputText}</Text>
          </Card>
        </ScrollView>
      )}
      <View style = {{flexDirection: 'row', justifyContent: 'space-xbetween', margin: 10}}>
      </View>
      <View style={{ flexDirection: 'row'}}>
      <TouchableOpacity
        style={isLoading ? [styles.actionButton, styles.disabledButtonStyle] : styles.actionButton}
        disabled={isLoading}
        onPress={() => storeData(outputText)}>
        <Text style={styles.createButtonText}>Save</Text>
      </TouchableOpacity>
      <TouchableOpacity 
        style={isLoading ? [styles.actionButton, styles.disabledButtonStyle] :   styles.actionButton} 
        onPress={handleShare}
        disabled={isLoading}>
        <Text style={styles.createButtonText}>Share</Text>
      </TouchableOpacity> 
      </View>
      <TouchableOpacity
      style={isLoading ? [styles.createButton, styles.disabledButtonStyle] :   styles.createButton}
        onPress={handleRecreatePress}
        disabled={isLoading}>
        <Text style={styles.createButtonText}>Re-create</Text>
      </TouchableOpacity>
    </SafeAreaView>
  </Modal>

  );
};

const AfterCustom = ({
  setWordLength
}) => {
  const [maxWord, setMaxWord] = useState('');
  function changeCustom (word) {
    setMaxWord(word);
    setWordLength(word);
  }
  return (
    <View style={{ position: 'relative', backgroundColor: backgroundColor}}>
      <KeyboardAvoidingView behavior="padding" enabled>
      <TextInput
            keyboardType="number-pad"
            maxLength={4}
            style={[
              styles.textInputAbout,
              {
                height: 40,
                width: 220,
                marginTop: 5,
              },
            ]}
            value={maxWord}
            onChangeText={(maxWord) => changeCustom(maxWord)}
            placeholder="Enter a value less than 500"
            placeholderTextColor={{ fontSize: 10, color: 'lightgrey' }}
            textAlignVertical="top"
            multiline={false}
            returnKeyType="done"
            onSubmitEditing={() => Keyboard.dismiss()}
          />
      </KeyboardAvoidingView>
    </View>
  );
};

const SeeAllAbout = ({
  modalVisible,
  setModalVisible,
  aboutText,
  handleTilePress,
  buttons,
  theme
}) => {

  const iconColor = (theme === 'dark' ? 'white': 'black'); 

  return (
    <Modal animationType="slide" transparent={false} visible={modalVisible}>
      <SafeAreaView
        style={theme === 'dark' ? styles.darkContainer : styles.lightContainer}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: 10,
          }}
          onPress={() => setModalVisible(false)}
        >
          <Ionicons name="ios-close" size={32} color={iconColor} />
        </TouchableOpacity>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'flex-start', marginRight: 18}}>
        <Text style={[{fontSize: 20, fontWeight: 'bold'}, theme === 'dark' ? {color: textColorDark} : {color: textColor}]}>Tell me about...</Text>
          <SeeAllTiles
            selectedType={aboutText}
            handleTilePress={handleTilePress}
            buttons={buttons}
            tileContent={aboutContent}
          />
        </View>

      </SafeAreaView>
    </Modal>
  );
};



const SeeAll = ({
  modalVisible,
  setModalVisible,
  selectedType,
  handleTilePress,
  buttons,
  theme
}) => {

  const iconColor = (theme === 'dark' ? 'white': 'black'); 

  return (
    <Modal animationType="slide" transparent={false} visible={modalVisible}>
      <SafeAreaView
        style={theme === 'dark' ? styles.darkContainer : styles.lightContainer}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: 10,
          }}
          onPress={() => setModalVisible(false)}
        >
          <Ionicons name="ios-close" size={32} color={iconColor} />
        </TouchableOpacity>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'flex-start', marginRight: 18}}>
        <Text style={[{fontSize: 20, fontWeight: 'bold'}, theme === 'dark' ? {color: textColorDark} : {color: textColor}]}>with a...</Text>
          <SeeAllTiles
            selectedType={selectedType}
            handleTilePress={handleTilePress}
            buttons={buttons}
            tileContent={tileContent}
          />
        </View>

      </SafeAreaView>
    </Modal>
  );
};

const SeeAllTiles = ({ selectedType, handleTilePress, buttons, tileContent }) => {
  return (
    <View style={styles.buttonRow}>

      <FlatList
        data={Object.keys(buttons)}
        numColumns={3}
        keyExtractor={(item) => item}
        renderItem={({ item }) => (
          <View style={[styles.buttonContainer, {flex: 1}]}>
            {selectedType == item &&
          <LinearGradient start={{x: 5, y: 0}} end={{x: 0.5, y: 10}}
          
        colors={['#FF4D4D', '#491DC5']}
        style={[styles.shadowClass, {borderRadius: 8}]}>
            <TouchableOpacity
              style={[
                styles.buttonType,
                styles.circleGradientSeeAll,
                styles.shadowClass,
                {marginLeft: 3,
                marginBottom: 3,
                marginRight: 3,
                },
              ]}
              onPress={() => handleTilePress(item)}>
              <Text style={styles.styleEmoji}>{tileContent[item]}</Text>
              <Text style={styles.buttonName}>{item}</Text>
            </TouchableOpacity></LinearGradient>}
            {selectedType != item &&
            <TouchableOpacity
            style={[
              styles.buttonType,
              {marginLeft: 5,
              marginBottom: 5,
              marginRight: 5,
              }, 
            ]}
            onPress={() => handleTilePress(item)}>
            <Text style={styles.styleEmoji}>{tileContent[item]}</Text>
            <Text style={styles.buttonName}>{item}</Text></TouchableOpacity>}
          </View>
        )}
      />
    </View>
  );
};




const TypeTiles = ({ selectedType, handleTilePress, buttons, theme, tileContent }) => {
  return (
    <ScrollView horizontal={true}>
    <View style={styles.buttonRow}>
      {buttons.map((button) => (

        <View key={button}  style={[styles.buttonContainer]}>
          {selectedType == button &&
          <LinearGradient start={{x: 0.5, y: 0}} end={{x: 0.5, y: 1}}
          
        colors={['#FF4D4D', '#491DC5']}
        style={[styles.shadowClass, {borderRadius: 6, marginRight: 4, marginLeft: 4}]}>
          <TouchableOpacity
            style={theme === 'dark' ? [
              styles.buttonDark,
              styles.circleGradient,
            ] : [
              styles.button,
              styles.circleGradient,
            ]}
            onPress={() => handleTilePress(button)}>
            <Text style={styles.styleEmoji}>{tileContent[button]}</Text>
            <Text style={styles.buttonName}>{button}</Text>
          </TouchableOpacity></LinearGradient>}
          {selectedType != button && 
          <TouchableOpacity
          style={theme === 'dark' ? [
            styles.buttonDark,
            styles.circleGradient,
            ] : [
              styles.button,
              styles.circleGradient,
            ]}
          onPress={() => handleTilePress(button)}>
          <Text style={styles.styleEmoji}>{tileContent[button]}</Text>
          <Text style={styles.buttonName}>{button}</Text>
        </TouchableOpacity>}
        </View>
      ))}
    </View>
    </ScrollView>
  );
};

const ButtonRow = ({ selectedStyle, handleStylePress, buttons, theme }) => {
  return (
    <ScrollView horizontal={true}>
      <View style={styles.buttonRow}>
        {buttons.map((button) => (

          <View key={button} style={styles.buttonContainer}>
            {selectedStyle == button &&
          <LinearGradient start={{x: 0.5, y: 0}} end={{x: 0.5, y: 1}}
        colors={['#FF4D4D', '#491DC5']}
        style={[styles.shadowClass, {borderRadius: 7, marginLeft: 3, marginRight: 3}]}>
            <TouchableOpacity
              style={theme === 'dark' ? [
                styles.buttonDark,
                styles.circleGradientSeeAll,
              ] : [
                styles.button,
                styles.circleGradientSeeAll,
              ]}
              onPress={() => handleStylePress(button)}>
              <Text style={styles.styleEmoji}>{styleToEmoji[button]}</Text>
              <Text style={theme === 'dark' ? [
                  styles.buttonNameDarkTone,
                ] : [
                    styles.buttonNameTone,
                  ]}>{button}</Text>
            </TouchableOpacity></LinearGradient>}
            {selectedStyle != button &&
            <TouchableOpacity
            style={[
              styles.button,
            ]}
            onPress={() => handleStylePress(button)}>
            <Text style={styles.styleEmoji}>{styleToEmoji[button]}</Text>
            <Text style={theme === 'dark' ? [
                  styles.buttonNameDarkTone,
                ] : [
                    styles.buttonNameTone,
                  ]}>{button}</Text>
          </TouchableOpacity>}

          </View>
        ))}
      </View>
    </ScrollView>
  );
};


const Home = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAboutVisible, setModalAboutVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [wordLength, setWordLength] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [typeText, setText] = useState('');
  const [aboutText, setAboutText] = useState('');
  const [postCreation, setPostCreation] = useState(false);
  const [postCustom, setPostCustom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [outputText, setOutputText] = useState('Waiting for response...');
  const [resultVisible, setResultVisible] = useState(false);
  //const [counterSave, setCounterSave] = useState(0);
  const theme = useColorScheme();

  var serverError = true;


  function addInspiration() {
    var index = randomNumberInRange(0, inspireInput.length-1);
    var d = inspireInput[index];
    setText(d['type']);
    setWordLength('100');
    setSelectedStyle(d['style']);
    setAboutText(d['text']);
    setPostCustom(false);
    setSelectedType(d['type']);
    setPostCustom(false);
    setWordLength(d['wordLength']);
    console.log("I am inspired!");
  }

  function resetForm() {
    setText('');
    setWordLength(null);
    setSelectedStyle(null);
    setAboutText('');
    setPostCustom(false);
    setSelectedType(null);
    console.log("resetted!");
  }

  const handleTilePress = (style) => {
    setSelectedType(style);
    setText(style);
    setModalVisible(false);
    setModalAboutVisible(false);
  };

  const handleButtonPress = (button) => {
    if (button == "Custom") {

      setPostCustom(true);
      setWordLength(button);

      console.log(button);
    } else {
      setPostCustom(false);
      setWordLength(button);
    }
    console.log(postCustom);
  };

  const handleStylePress = (style) => {
    setSelectedStyle(style);
  };

  const seeAllPress = () => {
    setModalVisible(true);
  };

  const scrollViewRef = useRef(null);
  const viewRef = useRef(null);

  const handleScrollTo = () => {
    scrollViewRef.current.scrollTo({
      x: 0,
      y: viewRef.current.y,
      animated: true,
    });
  };

  async function checkInternetConnection() {
  try {
    const supported = await
  Linking.canOpenURL('https://www.google.com/');
  console.log({supported});
    return supported;
  } catch (error) {
    return false;
  }
}

function handeAboutClose(item) {
  setAboutText(item);
  setModalAboutVisible(false);
}

  // function called when user clicks Create
  const handleCreatePress = () => {
    console.log({ typeText, wordLength, selectedStyle, aboutText });

    if (typeText === '') {
      if (Platform.OS === 'ios') {
        Alert.alert(
          'No type selected',
          'Please select the type of text you want to generate'
        );
      } else {
        alert('Please select the type of text you want to generate');
      }
      return;
    }

  // if (!checkInternetConnection()) {
  //   alert('Please check your internet connection');
  //   return;
  // }




    /*
    if (aboutText === '') {
      if (Platform.OS === 'web') {
        alert('Please enter something in the about field');
      } else {
        Alert.alert(
          'No about entered',
          'Please enter something in the about field'
        );
      }
      return;
    } */

    setResultVisible(true);
    setIsLoading(true);
    console.log({ isLoading });
    var data = {
      type: typeText,
      wordLength,
      style: selectedStyle,
      textInput: aboutText,
    };
    outputReady = false;
    
    axios
      .post('https://weave-backend.herokuapp.com/', data, {
        // USE http://127.0.0.1:5000/ for testing
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 15000,
      })
      .then(function (response) {
        outputReady = true;
        setIsLoading(false);
        console.log({ isLoading });
        setOutputText(response.data['text'].substring(2));
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          // If the request times out, display a message
          var message = 'The request timed out, which may be due to a lack of internet connection.';
          if (serverError) {
            message = 'The AI engine is experiencing high volumes of requests. Please try again later.';
          }
          if (Platform.OS === 'ios') {
            Alert.alert('Failed to load', message)
          } else {
          alert(message);
          }
          console.log(error);
        } else {
          // Handle other errors
          alert('An error occured: ' + error);
          console.log(error);
        }
        setIsLoading(false);
        outputReady = true;
      }); 
    setPostCreation(true);
    console.log({ postCreation });
  };

  useEffect(() => {
    if (postCreation) {
      return () => <AfterCreate />;
    }
    if (postCustom) {
      return () => <AfterCustom />;
    }
  }, [postCreation]);

  console.log(wordLength);

  // HTML component with form inputs
  return (
    <SafeAreaView 
    style={theme === 'dark' ? styles.darkContainer : styles.lightContainer}
    >
      <ScrollView ref={scrollViewRef} keyboardShouldPersistTaps="handled"> 
        <View style={styles.header}>
          <Text style={theme === 'dark' ? styles.headerTextDark : styles.headerText}>Awareness AI</Text>
          
          <View style={[
            
            styles.promptTextTop,
            {
              flexDirection: 'row',
            },
          ]}>

          <TouchableOpacity onPress={() => setInfoVisible(true)}>
            <Text style={{color: '#282424', marginRight: 15, textDecorationLine: 'underline', fontWeight: 'bold'}}>About</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => addInspiration()}>
            <Text style={{color: '#0C25A3', marginRight: 15, textDecorationLine: 'underline', fontWeight: 'bold'}}>Inspire Me</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => resetForm()}>
          <Text style={{color: '#EB0000', marginRight: 10, textDecorationLine: 'underline', fontWeight: 'bold'}}>Reset</Text>
          </TouchableOpacity>
          </View>
        </View>
      
        <View style={styles.formContainer}>
        <View style={[
              styles.promptRow,
              {
                flexDirection: 'row',
                borderTopColor: 'light grey', borderTopWidth: 1, width: '100%', borderStyle: 'solid'
              },
            ]}>
            <Text style={theme === 'dark' ? styles.promptTitleDark: styles.promptTitle}>Tell me about...</Text>
            <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', marginTop:10, marginRight: 5}} onPress={() => setModalAboutVisible(true)}>
              <Text style={[styles.seeAll, {color:"#0C25A3"}]}>See All</Text>
              <Ionicons name="chevron-forward-outline" size={20} color="#0C25A3" />
          </TouchableOpacity>
          </View>
          <TypeTiles
        theme={theme}
            selectedType={aboutText}
            handleTilePress={setAboutText}
            buttons={Object.keys(aboutContent)}
            tileContent={aboutContent}
          />
          <TextInput
            style={[
              styles.textInputAbout,
              {
                height: 50,
              },
            ]}
            value={aboutText}
            onChangeText={setAboutText}
            placeholder='Choose from above or create your own!'
            placeholderTextColor={{ fontSize: 10, color: 'lightgrey' }}
            textAlignVertical="top"
            multiline={true}
            numberOfLines={5}
            returnKeyType="done"
            onSubmitEditing={() => Keyboard.dismiss()}
          />

        <View style={[styles.promptRow, {flexDirection: 'row',}]}>
          <Text style={theme === 'dark' ? styles.promptTitleDarkAbout : styles.promptTitleAbout}>with a...</Text>
          <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', marginTop:10, marginRight: 5}} onPress={() => setModalVisible(true)}>
            <Text style={[styles.seeAll, {color:"#0C25A3"}]}>See All</Text>
            <Ionicons name="chevron-forward-outline" size={20} color="#0C25A3" />
          </TouchableOpacity>
        </View>
        <TypeTiles
        theme={theme}
            selectedType={selectedType}
            handleTilePress={handleTilePress}
            buttons={Object.keys(tileContent)}
            tileContent={tileContent}
          />
      
          <View
            style={[
              styles.buttonRowCount,
              {
                padding: 5,
              },
            ]}>
            <Text
              style={theme === 'dark' ? styles.wordCountTitleDark : styles.wordCountTitle}>
              Word Count:{' '}
            </Text>
            {['50', '150', '250', 'Custom'].map((button) => (
              <View key={button}>
              {(wordLength != null) && (wordLength == button || (button == "Custom" && wordLength != "50" && wordLength != "150" && wordLength != "250")) &&
                <LinearGradient start={{x: 0.5, y: 0}} end={{x: 0.5, y: 1}}
                
              colors={['#FF4D4D', '#491DC5']}
              style={[styles.shadowClass, {borderRadius: 7, marginRight:5}]}>
              <TouchableOpacity
                style={[
                  styles.wordLengthButtonChosen,
                ]}
                onPress={() => handleButtonPress(button)}>
                <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
                  {button}
                </Text>
              </TouchableOpacity></LinearGradient>}
              {(wordLength != button && (wordLength == null || wordLength == "Custom" || wordLength == "50" || wordLength == "150" || wordLength == "250" || button != "Custom")) &&
              <TouchableOpacity
                style={theme === 'dark' ? [
                  styles.wordLengthButtonDark,
                ] : [
                    styles.wordLengthButton,
                  ]}
                onPress={() => handleButtonPress(button)}>
                <Text style={theme === 'dark' ? [
                  { fontWeight: 'bold', fontSize: 18, color: '#FFF' },
                  wordLength === button ? { color: '#000' } : null,
                ] : [
                    { fontWeight: 'bold', fontSize: 18, color: '#000' },
                    wordLength === button ? { color: '#FFF' } : null,
                  ]}>
                  {button}
                </Text>
            </TouchableOpacity>
              }
              </View>
              
            ))}
          </View>
          {postCustom && (
            <AfterCustom
              setWordLength={setWordLength}
            />
          )}
          <Text style={theme === 'dark' ? styles.boldTextDark : styles.boldText}>
            Select Style:
          </Text>
            <ButtonRow
            theme={theme}
              selectedStyle={selectedStyle}
              handleStylePress={handleStylePress}
              buttons={Object.keys(styleToEmoji)}
            />
        </View>
        <TouchableOpacity
          style={[theme === 'dark' ? styles.createButtonDark : styles.createButton, {borderRadius: 10}]}
          onPress={handleCreatePress}>
          <Text style={styles.createButtonText}>Create</Text>
        </TouchableOpacity>
        <Info modalVisible={infoVisible} setModalVisible={setInfoVisible}/>
        <SeeAll modalVisible={modalVisible} setModalVisible={setModalVisible} selectedType={selectedType} handleTilePress={handleTilePress} buttons={tileContent} theme={theme}/>
        <SeeAllAbout modalVisible={modalAboutVisible} setModalVisible={setModalAboutVisible} selectedType={aboutText} handleTilePress={handeAboutClose} buttons={aboutContent} theme={theme}/>
        {postCreation && (
          <AfterCreate
            /* ref={viewRef} */
            outputText={outputText}
            setOutputText={setOutputText}
            handleCreatePress={handleCreatePress}
            setResultVisible={setResultVisible}
            resultVisible={resultVisible}
            isLoading={isLoading}
            setPostCreation={setPostCreation}
            theme={theme}
            // counterSave={counterSave}
            // setCounterSave={setCounterSave}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  lightContainer: {
    flex: 1,
    backgroundColor: backgroundColor,
  },
  darkContainer: {
    flex: 1,
    backgroundColor: backgroundColorDark,
  },
  header: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // backgroundColor: '#E7F0FB',
  },
  headerImage: {
    flexDirection: 'row',
  },
  headerText: {
    color: textColor,
    fontFamily: 'Optima-BoldItalic',
    padding: 15,
    fontSize: 25,
  },
  headerTextDark: {
    color: textColorDark,
    fontFamily: 'Optima-BoldItalic',
    padding: 15,
    fontSize: 25,
  },
  seeAll: {
    color: '#403C3C',
    textDecorationLine: 'underline',
    fontSize: 16,
    fontWeight: 'bold',
  },
  promptTitle: {
    color: textColor,
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 10,
    // marginBottom:2,
    marginLeft: 16,
  },
  promptTitleDark: {
    color: textColorDark,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 12,
    marginBottom: 2,
    marginLeft: 16,
  },
  promptTitleAbout: {
    color: textColor,
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 18,
    marginBottom: 6,
    marginLeft: 16,
  },
  promptTitleDarkAbout: {
    color: textColorDark,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 12,
    marginBottom: 10,
    marginLeft: 16,
  },
  promptText: {
    fontSize: 13,
    marginTop: 15,
    // marginBottom: 2,
    justifyContent: 'space-between',
  },
  promptTextTop: {
    fontSize: 13,
    marginTop: 22,
    justifyContent: 'space-between',
  },
  promptRow: {
    justifyContent: 'space-between',
    // marginTop: 5,
  },
  textInput: {
    backgroundColor: '#fff',
    height: 40,
    fontSize: 18,
    color: '#6C6C6C',
    fontWeight: 'bold',
    borderRadius: 10,
    marginTop: 0,
    padding: 5,
    marginLeft: 15,
    marginRight: 15
  },
  textInputDark: {
    backgroundColor: '#000',
    height: 40,
    fontSize: 18,
    color: '#6C6C6C',
    fontWeight: 'bold',
    borderRadius: 10,


    marginTop: 0,
    padding: 5,
    marginLeft: 15,
    marginRight: 15
  },
  textInputAbout: {
    backgroundColor: '#fff',
    height: 40,
    fontSize: 16,
    color: '#6C6C6C',
    fontWeight: 'bold',
    borderRadius: 10,
    marginTop: 0,
    padding: 5,
    marginLeft: 15,
    marginRight: 15
  },
  textInputAboutDark: {
    backgroundColor: '#000',
    height: 40,
    fontSize: 16,
    color: '#6C6C6C',
    fontWeight: 'bold',
    borderRadius: 10,
    marginTop: 0,
    padding: 5,
    marginLeft: 15,
    marginRight: 15
  },
  buttonRow: {
    flexDirection: 'row',
    marginTop: 8,
    marginLeft: 15,
    marginBottom: 20,
  },
  buttonRowCount: {
    flexDirection: 'row',
    marginLeft: 9,
  },
  shadowClass: {
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 3,
},
shadowOpacity: 0.27,
shadowRadius: 4.65,

elevation: 6,
  },
  button: {
    backgroundColor: buttonColor,
    padding: 5,
    marginRight: 2,
    marginLeft: 2,
    edgeWidth: 1,
    borderRadius: 10,
    width: 75,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 2,
    marginBottom: 2,
  },
  buttonDark: {
    backgroundColor: buttonColorDark,
    padding: 5,
    marginRight: 7,
    edgeWidth: 1,
    borderRadius: 10,
    width: 75,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
    marginLeft: 2,
  },
  buttonType: {
    backgroundColor: buttonColor,
    padding: 5,
    marginRight: 7,
    edgeWidth: 1,
    borderRadius: 10,
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 3
  },
  wordLengthButton: {
    backgroundColor: buttonColor,
    padding: 5,
    marginRight: 7,
    edgeWidth: 1,
    borderRadius: 10,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderRadius: 5,
    marginTop: 2,
  },
  wordLengthButtonChosen: {
    backgroundColor: buttonColor,
    marginRight: 2,
    marginLeft: 2,
    marginBottom: 2,
    padding: 5,
    edgeWidth: 1,
    borderRadius: 10,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderRadius: 5,
    marginTop: 2,
  },
  wordLengthButtonDark: {
    backgroundColor: buttonColorDark,
    padding: 5,
    marginRight: 7,
    edgeWidth: 1,
    borderRadius: 10,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  wordCountTitle: {
      color: textColor,
      fontWeight: 'bold',
      fontSize: 16,
      paddingTop: 5,
      marginTop: 2,
  },
  waitingMessage: {
    color: textColor,
    fontWeight: 'bold',
    fontSize: 16,
    paddingTop: 5,
    marginTop: 45,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 15,
    padding: 15,
    textAlign: 'center',
},
  wordCountTitleDark: {
    color: textColorDark,
    fontWeight: 'bold',
    fontSize: 16,
    paddingTop: 5,
    marginTop: 10,
  },
  boldText: {
    color: textColor,
    marginTop: 15,
    fontWeight: 'bold',
    marginLeft: 15,
    fontSize: 16,
    marginBottom: 2,
  },
  boldTextDark: {
    color: textColorDark,
    marginTop: 15,
    fontWeight: 'bold',
    marginLeft: 15,
    fontSize: 16,
    marginBottom: 2,
  },
 
  createButton: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    borderRadius: 10,
    marginTop: 7,
    backgroundColor: createButtonColor,
  },
  createButtonSave: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    borderRadius: 10,
    marginTop: 10,
    backgroundColor: createButtonColor,
  },
  createButtonDark: {
    height: 50,
    backgroundColor: createButtonColorDark,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    borderRadius: 10,
    marginTop: 10,
  },
  actionButton: {
    height: 50,
    backgroundColor: createButtonColor,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    borderRadius: 10,
    flex: 1,
    width: '100%'
  },
  disabledButtonStyle: {
    opacity: "35%",
    backgroundColor: '#DDD'
  },
  createButtonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  createButtonTextDark: {
    color: '#000',
    fontSize: 18,
    fontWeight: 'bold',
  },
  styleEmoji: {
    fontSize: 45,
    padding: 8,
    textAlign: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
  },
  buttonName: {
    color: '#000',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  buttonNameDark: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  buttonNameTone: {
    color: '#000',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  buttonNameDarkTone: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  buttonClipBoard: {
    backgroundColor: 'lightgrey',
    opacity: 0.8,
    alignItems: 'center',
    padding: 5,
    maxWidth: 'fit-content',
    paddingHorizontal: 10,
    borderRadius: 4,
    alignSelf: 'center',
  },
  buttonTextClipBoard: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  resultTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  circleGradient: {
    margin: 1,
    backgroundColor: "white",
    borderRadius: 5,
  },
  circleGradientSeeAll: {
    margin: 1,
    borderRadius: 5,
  },
  visit: {
    margin: 4,
    paddingHorizontal: 6,
    textAlign: "center",
    backgroundColor: "white",
    color: '#008f68',
    fontSize: 12
  },
  copyButton: {
    height: 40,
    backgroundColor: createButtonColor,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    borderRadius: 10,
    flex: 1,
    width: '30%',
    marginLeft: 315,
    marginBottom: 0,
  },

});

export default Home;