import React, { useState, useEffect } from 'react';
import { View, FlatList, Text, Share, TouchableOpacity, SafeAreaView, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ScrollView } from 'react-native-gesture-handler';


const backgroundColor = '#E7F0FB';
const backgroundColorHeader = '#E7F0FB'

const Saved = () => {
  var [storedResponses, setStoredResponses] = useState([]);
  var [expanded, setExpanded] = useState(false);

  storedResponses = storedResponses.filter(function(item) {
    return item !== null
  })
  for (var i=0; i < storedResponses.length; i++) {
    console.log(storedResponses[i].key.toString() == 'NaN');
    if (storedResponses[i].key.toString() == 'NaN') {
      storedResponses[i].key = Date.now(-8640000000000000).toString();
      storedResponses[i].value = "Your saved content will appear here.";
    }
  }

  useEffect(() => {
    const fetchStoredResponses = async () => {
      try {
        const keys = await AsyncStorage.getAllKeys();
        const storedResponseObjects = await AsyncStorage.multiGet(keys);
        setStoredResponses(
          storedResponseObjects
            .map(([key, value]) => ({ key: parseInt(key, 10), value }))
            .sort((a, b) => b.key - a.key)
        );
      } catch (error) {
        console.error('Error fetching stored responses: ', error);
      }
    };

    fetchStoredResponses();
  }, []);

  const refreshResponses = async () => {
    try {
      const keys = await AsyncStorage.getAllKeys();
      const storedResponseObjects = await AsyncStorage.multiGet(keys);
      setStoredResponses(
        storedResponseObjects
          .map(([key, value]) => ({ key: parseInt(key, 10), value }))
          .sort((a, b) => b.key - a.key)
      );
    } catch (error) {
      console.error('Error fetching stored responses: ', error);
    }
  };

  const shareResponse = async (value) => {
    try {
      await Share.share({
        message: value,
      });
    } catch (error) {
      console.error('Error sharing response: ', error);
    }
  };

  const deleteResponse = async (key) => {
    try {
      await AsyncStorage.removeItem(key.toString());
      const keys = await AsyncStorage.getAllKeys();
      const storedResponseObjects = await AsyncStorage.multiGet(keys);
      setStoredResponses(
        storedResponseObjects
          .map(([key, value]) => ({ key: parseInt(key, 10), value }))
          .sort((a, b) => b.key - a.key)
      );
    } catch (error) {
      console.error('Error deleting response: ', error);
    }
  };

  const expandAll = () => {
    setExpanded(true);
  };

  const collapseAll = () => {
    setExpanded(false);
  };

  return (
    <SafeAreaView style={{backgroundColor:backgroundColor}}>
      <View style={styles.headerContainer}> 
        <TouchableOpacity
          style={styles.expandButton}
          onPress={expandAll}
        >
          <Text style={styles.expandText}>Expand</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.collapseButton}
          onPress={collapseAll}
        >
          <Text style={styles.collapseText}>Collapse</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.totalNumber}>
        <Text style={styles.totalNumberText}> 
          Total: {storedResponses.length}
        </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.refreshButton}
          onPress={refreshResponses}
        >
          <Ionicons name="ios-refresh" size={27} color="#282424" />
        </TouchableOpacity>
      </View>
      <View>
        <FlatList
          data={storedResponses}
          renderItem={({item}) => (
            <View style={styles.cardContainer}>
              <View style={styles.responseTextContainer}>
                <Text numberOfLines={expanded ? null : 5}>{item.value}</Text>
              </View>
              <TouchableOpacity
                style={styles.shareButton}
                onPress={() => shareResponse(item.value)}
              >
                <Ionicons name="ios-share" size={20} color="#606162" />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.deleteButton}
                onPress={() => deleteResponse(item.key)}
              >
                <Ionicons name="ios-trash" size={20} color="#EB0000" />
              </TouchableOpacity>
            </View>
          )}
          keyExtractor={(item) => item.key.toString()}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#282424',
    backgroundColor: backgroundColorHeader,
  },
  refreshButton: {
    paddingVertical: 10,
  },
  expandButton: {
    backgroundColor: '#0C25A3',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  expandText: {
    color: 'white',
    fontSize: 16,
  },
  collapseButton: {
    backgroundColor: '#EB0000',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  collapseText: {
    color: 'white',
    fontSize: 16,
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    backgroundColor: '#9FFCA3',
    marginBottom: 10,
  },
  responseTextContainer: {
    flex: 1,
  },
  shareButton: {
    position:'absolute',
    padding: 10,
    right: 0,
    top:0,
  },
  deleteButton: {
    position:'absolute',
    padding: 10,
    right: 0,
    bottom:0,
  },
  totalNumber: {
    fontWeight: 'bold',
    backgroundColor: '#282424',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  totalNumberText: {
    fontSize: 16,
    color: 'white',
  },
});

export default Saved;
