import React, { useState } from 'react';
import { View, Text, Button, TextInput, Modal, Linking, StyleSheet, TouchableOpacity, SafeAreaView, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const Info = ({
    modalVisible,
    setModalVisible,
    theme
}) => {


  const iconColor = (theme === 'dark' ? 'white': 'black'); 

  return (
    <Modal transparent={false} visible={modalVisible} animationType="fade">
        <SafeAreaView>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: 5,
          }}
          onPress={() => setModalVisible(false)}
        >
          <Ionicons name="ios-close" size={32} color={iconColor} />
        </TouchableOpacity>
    <View>
      <Text style={styles.headerText}>About</Text>
      <Text style={styles.textCreators}>Created with ❤️ by Ethan Jagoda, Jayaditya Sethi, and Sahil Mehta</Text>
      <Text style={styles.text}>Activism AI is the most convenient app to learn about environmental issues and create content for activism.</Text>
      <Text style={styles.text}>Since the app is early-stage, we would love to hear any feedback.</Text>
      <Image
        style={styles.image}
        source={require('../assets/icon.png')}
      />
      <Button
        style={styles.feedback}
        title="Send Feedback"
        onPress={() => {
          const emailAddress = 'jayaditya@berkeley.edu, mehtasahil881@berkeley.edu, ethanjags@berkeley.edu';
          const emailSubject = 'Feedback for Scribble AI';
          Linking.openURL(`mailto:${emailAddress}?subject=${emailSubject}`);
        }}
      />
    </View>
    </SafeAreaView>
    </Modal>
  );
}

export default Info;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    textCreators: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        marginRight: 25,
        marginLeft: 25,
    },
    text: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        marginRight: 25,
        marginLeft: 25,
    },
    image: {
      width: 150,
      height: 150,
      alignSelf: 'center',
      marginTop: 20,
      marginBottom: 40,
    },
    feedback: {
      marginRight: 25,
      marginLeft: 25,
    }
});

